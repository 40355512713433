// Custom.scss

$primary: #b70200 !default;
//$primary: #ff3300;
//$secondary: #e0e0e0;
//$success: #5cb85c;
//$info: #5bc0de;
//$warning: #f0ad4e;
//$danger: #ff4136;

//zweiter versuch:
//$primary:    #2196f3;
//$success:    #4caf50;
//$info:       #00bcd4;
//$warning:    #ff9800;
//$danger:     #f44336;

$font-weight-normal:          400 !default;
$font-weight-bold:            600 !default;

$font-family-sans-serif:      "Open Sans" ,system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
@import "bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;1,400;1,600&display=swap');


